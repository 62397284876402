<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <div class="page-wrap pt188">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        商品物流详情
      </div>
    </top-bar-arrow>
    <div class="main">
      <template v-for="(item, index) in orderInfo.product_list">
        <div class="product-order-item box-shadow" :key="index">
          <div class="left">
            <img :src="item.small_image_url" mode="" />
          </div>
          <div class="center">
            <div class="line1">{{ item.product_name }}</div>
          </div>
        </div>
      </template>

      <div class="tips box-shadow">
        <div class="left">
          <img src="../../assets/ty_btn_jingao@2x.png" mode="" />
        </div>
        <div class="right">由于商品本身性质，商品不可退货，如有质量问题可申请换货。</div>
      </div>

      <div class="remarks-info box-shadow">
        <div class="title">补充描述</div>
        <div class="input-wrap">
          <van-field
                  v-model="formData.exchange_des"
                  rows="4"
                  autosize
                  type="textarea"
                  maxlength="300"
                  placeholder="补充描述，有助于我们更好的处理售后"
                  show-word-limit
          />
        </div>
        <div class="upload-wrap">
          <htz-image-upload ref="imgUpload"></htz-image-upload>
        </div>
      </div>
      <div class="btn-line">
        <div class="btn-normal" @click="submitForm">提交申请</div>
      </div>
    </div>
  </div>
</template>

<script>
  import topBarArrow from '../../components/top-bar-arrow'
  import htzImageUpload from '../../components/htz-image-upload'

  import { exchangeCommit } from '@/common/api/express.js'
  export default {
    components: {
      topBarArrow,
      htzImageUpload
    },
    data() {
      return {
        formData: {
          exchange_des: ''
        },
        inputNum: 0,
        dataList: [],
        types: 'image',
        orderInfo: {},
        ceshiData: []
      };
    },
    mounted() {
      this.orderInfo = this.$route.params.orderInfo
    },
    methods:{
      submitForm () {
        exchangeCommit({
          data: {
            logistics_order_no: this.orderInfo.logistics_order_no,
            exchange_des: this.formData.exchange_des,
            image_url: this.$refs.imgUpload.uploadLists.join('||')
          },
          success: res => {
            // uni.navigateBack({
            //
            // })
          }
        })
      },
      inputChange (value) {
        this.inputNum = value.length || 0
        console.log(value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./exchangeGoods.scss"
</style>

