import baseUtil from "../js/utils.js"

// 获取openId
export const getOpenId = (data) => baseUtil.sendRequestAsync({
	url: '/Wechat/getOpenId',
	data: data
})
// 获取手机号码
export const getphone = (data) => baseUtil.sendRequestAsync({
	url: '/Wechat/getPhone',
	data: data
})
// 检测微信小程序是否已绑定用户
export const isBindWxApplets = (data) => baseUtil.sendRequestAsync({
	url: '/Wechat/isBindWxApplets',
	data: data
})
// 用户登录
export const login = (data) => baseUtil.sendRequestAsync({
	url: '/User/login',
	data: data
})
// 获取七牛token
export const getQiNiuToken = (data) => baseUtil.sendRequestAsync({
	url: '/OtherConf/getQiNiuToken',
	data: data
})

