<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <div class="htz-image-upload-list">
    <div class="htz-image-upload-Item" v-for="(item,index) in uploadLists" :key="index">
      <image :src="item" @click="imgPrediv(index)"></image>
      <div class="htz-image-upload-Item-del" @click="imgDel(index)">×</div>
    </div>
    <div class="htz-image-upload-Item htz-image-upload-Item-add" v-if="uploadLists.length<max" @click="imgAdd">
      <div class="icon">+</div>
      <div class="msg">
        <div class="msg-1">上传凭证</div>
        <div class="msg-1">({{ uploadLists.length }}/3)</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getQiNiuToken } from '@/common/api/api.js'

  const qiniuUploader = require("../common/js/qiniuUploader");

  export default {
    name: 'htz-image-upload',
    data() {
      return {
        uploadLists: [],
        qiniuUploader,
        max: 3
      }
    },
    mounted: function() {
      this.uploadLists = []
    },
    methods: {
      imgDel(index) {
        // uni.showModal({
        //   title: '提示',
        //   content: '您确定要删除么?',
        //   success: (res) => {
        //     if (res.confirm) {
        //       let delUrl=this.uploadLists[index]
        //       this.uploadLists.splice(index, 1)
        //     } else if (res.cancel) {}
        //   }
        // });
      },
      imgPrediv(index) {
        // uni.predivImage({
        //   urls: this.uploadLists,
        //   current: index,
        //   loop: true,
        // });
      },
      // 新增图片
      imgAdd() {
        let nowNum = Math.abs(this.uploadLists.length - this.max);
        let thisNum = (this.chooseNum > nowNum ? nowNum : this.chooseNum) //可选数量
        // uni.chooseImage({
        //   count: thisNum,
        //   sizeType: ['original', 'compressed'], //可以指定是原图还是压缩图，默认二者都有
        //   sourceType: this.sourceType,
        //   success: (res) => {
        //     this.imgUpload(res.tempFilePaths, res.tempFiles)
        //   }
        // });
      },
      // 循环上传图片
      async imgUpload(tempFilePaths, tempFiles) {
        // uni.showLoading({
        //   title: '上传中'
        // });

        const tokenRes = await getQiNiuToken()
        this.qnToken = tokenRes.token

        tempFilePaths.forEach((img, index) => {
          // 交给七牛上传
          qiniuUploader.upload(img, (res) => {
            this.uploadLists.push(res.imageURL)
          }, (error) => {
            console.log('error: ' + error);
          }, {
            region: 'ECN',	//华东
            domain: 'http://mhstatic.dinotd.com/', //
            key: img.split('temp/')[1], // 上传到七牛云图片就是这名字了
            uptoken: this.qnToken, // 由其他程序生成七牛 uptoken
          }, (res) => {
            console.log('上传进度', res.progress)
            console.log('已经上传的数据长度', res.totalBytesSent)
            console.log('预期需要上传的数据总长度', res.totalBytesExpectedToSend)
          }, () => {
            // 取消上传
          }, () => {
            // `before` 上传前执行的操作
          }, (err) => {
            // `complete` 上传接受后执行的操作(无论成功还是失败都执行)
          });
        })
      },
    }
  }
</script>

<style lang="scss">
  .htz-image-upload-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .htz-image-upload-Item {
    width: 124px;
    height: 124px;
    margin: 13px;
    border-radius: 24px;
    position: relative;
  }

  .htz-image-upload-Item image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .htz-image-upload-Item-add {
    background-image: linear-gradient(-45deg, #FFFFFF 0%, #FAFAFA 31%, #DEDEDE 100%);
    border: 0 solid #8F8CA1;
    box-shadow: inset 8px 6px 20px 0 rgba(255,255,255,0.25), inset -8px -6px 20px 0 rgba(255,255,255,0.65);
    border: 1px solid #DEDEDE;
    border-radius: 24px;
    width: 124px;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & .icon {
      width: 30px;
      height: 30px;
      color: #8F8CA1;
      font-size: 52px;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 10px;
    }
    & .msg {
      font-size: 24px;
      color: #8F8CA1;
      text-align: center;
      line-height: 30px;
    }
  }

  .htz-image-upload-Item-del {
    background-color: #f5222d;
    font-size: 28px;
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 26px;
    text-align: center;
    top: 0;
    right: 0;
    z-index: 100;
    color: #fff;
    font-weight: bold;
    border-radius: 30px;
  }
</style>

